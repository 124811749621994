import React from "react";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useForm } from "react-hook-form";

//Components
import SandboxHeader from "./Header";
import HeroGradient from "../../components/HeroGradient";

//Styles
import * as styles from "../../styles/components/SandboxComponents.module.css";

// Utils
import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";

const MotionLink = motion(Link);

const FirstTimeMerchant = () => {
  const { t } = useTranslation();
  // const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // setIsLoading(true)
    //Request & Response
    console.log(data);
    // setEmailSent(true);
  };
  const HeroSection = () => {
    return (
      <section>
        <div className={styles.heroSection__wrapper}>
          <HeroGradient />
          <motion.div
            {...revealContainerWithChildrenProps}
            className={`${styles.heroSection__content} sectionContainer`}
          ></motion.div>
        </div>
      </section>
    );
  };

  const LogInForm = (
    <motion.div className="flex justify-center w-full">
      <motion.div className={`${styles.loginForm__wrapper}`}>
        {/* text */}
        <motion.div>
          <motion.h2 className={styles.signUpForm__title}>
            {t("sandbox.signup.login.form.title")}
          </motion.h2>
          <motion.p className={styles.signUpForm__description}>
            {t("sandbox.signup.login.form.description")}
          </motion.p>
        </motion.div>

        {/* Form */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.signUpForm__form}
        >
          <div className="flex flex-col w-full gap-6">
            <motion.div variants={revealVariant}>
              <label
                htmlFor="merchant_name"
                className={styles.signUpForm__label}
              >
                {t("sandbox.signup.merchant.name")}
              </label>
              <input
                placeholder={t("sandbox.signup.merchant.placeholder")}
                type="text"
                id="merchant_name"
                className={`input  ${errors.merchant_name ? "error" : ""} ${
                  styles.signUpForm__input
                }`}
                {...register("merchant_name", { required: true })}
              />
              {errors.merchantName && (
                <span className={styles.signUpForm__error}>
                  {errors.merchantName.type === "required"
                    ? t("sandbox.signup.form.error.required")
                    : t("sandbox.signup.form.error.validation", {
                        field: t(
                          "sandbox.signup.form.merchant.name.label"
                        ).toLowerCase(),
                      })}
                </span>
              )}
            </motion.div>

            <motion.div variants={revealVariant}>
              <label htmlFor="terminalId" className={styles.signUpForm__label}>
                {t("sandbox.signup.terminal.id")}
              </label>
              <input
                placeholder={t("sandbox.signup.terminal")}
                type="text"
                value={t("sandbox.signup.terminal")}
                disabled
                id="terminalId"
                className={`input  ${errors.terminalId ? "error" : ""} ${
                  styles.signUpForm__input
                }`}
              />
            </motion.div>

            <motion.div variants={revealVariant}>
              <label htmlFor="merchantId" className={styles.signUpForm__label}>
                {t("sandbox.signup.merchant.id")}
              </label>
              <input
                placeholder={t("sandbox.signup.merchant")}
                value={t("sandbox.signup.merchant")}
                disabled
                type="text"
                id="merchantId"
                className={`input ${styles.signUpForm__input}`}
              />
            </motion.div>
          </div>

          {/* Button */}
          <motion.div>
            <MotionLink
              type="submit"
              variants={revealVariant}
              className={`mt-[34px] button button--secondary w-full`}
            >
              {t("sandbox.submitButton")}
            </MotionLink>
          </motion.div>
        </form>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="layout">
      <SandboxHeader className={`pb-2 ${styles.heroSection__wrapper}`} />
      <main>
        <HeroSection />
        {LogInForm}
      </main>
    </div>
  );
};

export default FirstTimeMerchant;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "sandbox", "contact", "store-hub"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
